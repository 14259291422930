<template>
    <div class="login-view">
        <form class="l-padded l-stack l-gap-2" @submit.prevent="handleSubmit">
            <div class="l-inline l-justify-end">
                <WhitelabledLogo height="40" transition />
            </div>

            <template v-if="isResetting">
                <h2>{{ $t('titleResetting') }}</h2>

                <FormInput
                    v-model="$v.email.$model"
                    v-focus
                    :label="$t('email')"
                    :show-required-error="$v.email.$dirty && !$v.email.required"
                    :errors="
                        $v.email.$dirty && !$v.email.email
                            ? [$t('emailInvalid')]
                            : []
                    "
                    required
                />
            </template>

            <template v-else-if="!isLoginSucceed">
                <h1>{{ $t('title') }}</h1>

                <h2>{{ $t('subtitle') }}</h2>

                <transition name="fade">
                    <span v-if="isLoginFailed" class="form-error">
                        {{ $t('loginFailed') }}
                    </span>
                </transition>

                <FormInput
                    v-model="$v.username.$model"
                    v-focus
                    :label="$t('username')"
                    :show-required-error="
                        $v.username.$dirty && !$v.username.required
                    "
                    data-test-username
                    required
                />

                <transition name="fade">
                    <FormInput
                        v-if="isUsernameEntered"
                        ref="passwordInput"
                        v-model="$v.password.$model"
                        type="password"
                        :label="$t('password')"
                        :show-required-error="
                            $v.password.$dirty && !$v.password.required
                        "
                        data-test-password
                        required
                    />
                </transition>
            </template>

            <template v-else>
                <div>
                    <p class="form-label">
                        {{ $t('simulateTip') }}
                    </p>

                    <UserSelect
                        v-model="simulationUser"
                        :exclude="simulationUsersExcluded"
                        show-details
                    />
                </div>
            </template>

            <div class="l-inline l-gap-2 l-center-v l-spread ">
                <div>
                    <button
                        v-if="!isLoginSucceed"
                        type="button"
                        class="link"
                        :disabled="isLoading"
                        @click="isResetting = !isResetting"
                    >
                        {{
                            isResetting
                                ? $t('backToLogin')
                                : $t('forgotPassword')
                        }}
                    </button>
                </div>

                <div class="l-inline l-gap-2 l-center-v l-justify-end">
                    <transition name="fade">
                        <VSpinner
                            v-if="isDisabled"
                            :size="24"
                            :speed="1"
                            line-fg-color="black"
                            line-bg-color="transparent"
                        />
                    </transition>

                    <div>
                        <AxButton ref="submitButton" :disabled="isDisabled">
                            {{
                                isResetting
                                    ? $t('reset')
                                    : !isUsernameEntered
                                    ? $t('continue')
                                    : !isLoginSucceed
                                    ? $t('login')
                                    : !simulationUser
                                    ? $t('skip')
                                    : $t('simulate')
                            }}
                        </AxButton>
                    </div>
                </div>
            </div>

            <transition name="fade">
                <transition-group
                    v-if="
                        !isLoginSucceed &&
                            !isResetting &&
                            identityProvidersFiltered.length
                    "
                    name="fade"
                    class="l-stack l-gap-1"
                    tag="div"
                >
                    <div
                        v-for="provider in identityProvidersFiltered"
                        :key="provider.name"
                        class="list-complete-item"
                    >
                        <a :href="provider.frontend_login_url">
                            <AxButton type="button">
                                {{ $t('loginWith', [provider.name]) }}
                            </AxButton>
                        </a>
                    </div>
                </transition-group>
            </transition>
        </form>

        <div
            v-if="!isLoginSucceed && !!linkToPlatformWebsite"
            class="l-padded l-stack l-gap-1"
        >
            <h3>{{ $t('noLoginYet') }}</h3>

            <p>
                <a :href="linkToPlatformWebsite" target="_blank">
                    {{ $t('moreInfo') }}
                </a>
            </p>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { email, required } from 'vuelidate/lib/validators'
import VSpinner from 'vue-simple-spinner'

import { httpHelper, languageHelper } from '@/utils'
import { commonService } from '@/service/store.service'
import AxButton from '@/components/AxButton'
import FormInput from '@/components/FormInput'
import UserSelect from '@/components/UserSelect'
import WhitelabledLogo from '@/components/WhitelabledLogo'
import Whitelabling from '@/mixins/Whitelabling'

export default {
    name: 'PublicLayout',
    components: {
        AxButton,
        FormInput,
        UserSelect,
        VSpinner,
        WhitelabledLogo,
    },
    mixins: [Whitelabling],
    props: {
        nextUrl: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            email: '',
            identityProviders: [],
            identityProvidersFiltered: [],
            isFetchingIdentityProviders: false,
            isLoading: false,
            isLoginFailed: false,
            isLoginSucceed: false,
            isResetting: false,
            isUsernameEntered: false,
            linkToPlatformWebsite: process.env.VUE_APP_PLATFORM_WEBSITE_LINK,
            password: '',
            simulationUser: null,
            username: '',
        }
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUserId', 'isStaff', 'defaultTab']),
        isDisabled() {
            return (
                this.isLoading ||
                (this.isUsernameEntered && this.isFetchingIdentityProviders)
            )
        },
        simulationUsersExcluded() {
            return [this.getCurrentUserId]
        },
    },
    watch: {
        identityProviders() {
            this.filterIdentityProviders()
        },
        isUsernameEntered(isUsernameEntered, wasUsernameEntered) {
            this.filterIdentityProviders()
            if (!isUsernameEntered && wasUsernameEntered) {
                this.password = ''
            }
        },
        username() {
            this.isUsernameEntered = false
        },
    },
    async mounted() {
        this.isLoading = true
        if (await this.loginWithTokenParams()) {
            this.isUsernameEntered = true
            await this.handleUserLoad()
            return
        }
        this.isLoading = false

        try {
            this.isFetchingIdentityProviders = true
            const { data } = await httpHelper.getRecursively('/idps/')
            this.identityProviders = data.results
        } finally {
            this.isFetchingIdentityProviders = false
        }
    },
    methods: {
        ...mapActions('auth', [
            'loadUserInfo',
            'login',
            'loginWithTokenParams',
            'simulateUser',
        ]),
        ...mapActions('common', ['loadData']),
        filterIdentityProviders() {
            this.identityProvidersFiltered = this.identityProviders.filter(
                provider =>
                    provider.show_sso_option_upfront ||
                    (this.isUsernameEntered &&
                        new RegExp(
                            `^[\\w\\.]+@${provider.domain_name}$`,
                            'i'
                        ).test(this.username))
            )
        },
        async handlePasswordReset() {
            try {
                this.isLoading = true
                await httpHelper.post('/password-reset/', {
                    email: this.email,
                })
                this.isResetting = false
                this.$notify({
                    title: this.$t('titleResetting'),
                    text: this.$t('resetConfirmation'),
                })
            } finally {
                this.isLoading = false
            }
        },
        async handleSubmit() {
            if (this.isResetting) {
                if (this.isValid()) {
                    await this.handlePasswordReset()
                }
                return
            }

            if (!this.isUsernameEntered) {
                if (this.isValid()) {
                    this.isUsernameEntered = true
                    this.$nextTick(() => {
                        this.$refs.passwordInput.$el.focus()
                    })
                }
                return
            }

            if (this.isLoginSucceed) {
                if (this.simulationUser) {
                    await this.simulateUser(this.simulationUser)
                }
                this.redirect()
                return
            }

            this.isLoginFailed = false
            if (this.isValid()) {
                try {
                    this.isLoading = true
                    await this.login({
                        username: this.username,
                        password: this.password,
                    })
                } catch {
                    this.isLoading = false
                    this.isLoginFailed = true
                    return
                }

                await this.handleUserLoad()
            }
        },
        async handleUserLoad() {
            await this.loadUserInfo()
            this.$root.$i18n.locale = languageHelper.getUsersLanguage()

            if (this.isStaff) {
                await commonService.populateUsers()
                this.isLoading = false
                this.isLoginSucceed = true
                this.$nextTick(() => {
                    this.$refs.submitButton.$el.focus()
                })
            } else {
                this.isLoading = false
                this.redirect()
            }
        },
        isValid() {
            this.$v.$touch()
            return !this.$v.$invalid
        },
        redirect() {
            const redirectUrl = this.nextUrl || `/${this.defaultTab}` || '/'
            this.$router.push(redirectUrl, this.loadData)
        },
    },
    validations() {
        return this.isResetting
            ? {
                  email: {
                      email,
                      required,
                  },
              }
            : {
                  username: {
                      required,
                  },
                  ...(this.isUsernameEntered && {
                      password: {
                          required,
                      },
                  }),
              }
    },
}
</script>

<i18n>
{
    "en": {
        "backToLogin": "Back to login",
        "continue": "Continue",
        "email": "Email",
        "emailInvalid": "Invalid E-Mail",
        "forgotPassword": "Forgotten password?",
        "login": "Login",
        "loginFailed": "No user for this username password combination found.",
        "loginWith": "Login with {0}",
        "moreInfo": "More information about the platform is available here.",
        "noLoginYet": "No login yet?",
        "password": "Password",
        "reset": "Send reset link",
        "resetConfirmation": "A message with a link to reset the password has been sent to the specified email address.",
        "simulate": "Simulate",
        "simulateTip": "Select user to simulate",
        "skip": "Skip",
        "subtitle": "Please log in.",
        "title": "Welcome.",
        "titleResetting": "Password reset.",
        "username": "Username"
    },
    "de": {
        "backToLogin": "Zurück zum Login",
        "continue": "Weiter",
        "email": "Email",
        "emailInvalid": "Ungültige E-Mail",
        "forgotPassword": "Passwort vergessen?",
        "login": "Einloggen",
        "loginFailed": "Keinen Benutzer für diese Kombination von Benutzernamen und Passwort gefunden.",
        "loginWith": "Einloggen mit {0}",
        "moreInfo": "Mehr Informationen zur Plattform gibt es hier.",
        "noLoginYet": "Noch kein Login?",
        "password": "Passwort",
        "reset": "Link senden",
        "resetConfirmation": "Eine Nachricht mit einem Link zum Zurücksetzen des Passworts wurde an die angegebene E-Mail-Adresse gesendet.",
        "simulate": "Simulieren",
        "simulateTip": "Benutzer für Simulation auswählen",
        "skip": "Überspringen",
        "subtitle": "Bitte melden Sie sich an.",
        "title": "Willkommen.",
        "titleResetting": "Passwort zurücksetzen.",
        "username": "Benutzername"
    },
    "fr": {
        "backToLogin": "Retour à la connexion",
        "continue": "Suivant",
        "email": "E-mail",
        "emailInvalid": "E-mail invalide",
        "forgotPassword": "Mot de passe oublié ?",
        "login": "Se connecter",
        "loginFailed": "Aucun utilisateur pour cette combinaison nom d'utilisateur-mot de passe n'a été trouvé.",
        "loginWith": "Se connecter avec {0}",
        "moreInfo": "Plus d'informations sur la plateforme sont disponibles ici.",
        "noLoginYet": "Pas de compte ?",
        "password": "Mot de passe",
        "reset": "Envoyer le lien",
        "resetConfirmation": "Un message contenant un lien pour réinitialiser le mot de passe a été envoyé à l'adresse électronique spécifiée.",
        "simulate": "Simuler",
        "simulateTip": "Selectionner l'utilisateur à simuler",
        "skip": "Skip",
        "subtitle": "Veuillez vous connecter.",
        "title": "Bienvenue.",
        "titleResetting": "Réinitialiser le mot de passe.",
        "username": "Nom d'utilisateur"
    },
    "it": {
        "backToLogin": "Torna al login",
        "continue": "Avanti",
        "email": "E-mail",
        "emailInvalid": "E-Mail non valido",
        "forgotPassword": "Password dimenticata?",
        "login": "Login",
        "loginFailed": "Login non valido.",
        "loginWith": "Login con {0}",
        "moreInfo": "Ulteriori informazioni sulla piattaforma sono disponibili qui.",
        "noLoginYet": "Non sei ancora registrato?",
        "password": "Password",
        "reset": "Invia link",
        "resetConfirmation": "All'indirizzo e-mail specificato è stato inviato un messaggio con un link per reimpostare la password.",
        "simulate": "Simulate",
        "simulateTip": "Selezionare l'utente da simulare",
        "skip": "Salta",
        "subtitle": "Effettua il login.",
        "title": "Benvenuto.",
        "titleResetting": "Reimposta password.",
        "username": "Nome utente"
    }
}
</i18n>

<style lang="scss" scoped>
.login-view {
    a,
    .link {
        margin: 0;
        padding: 0;
        display: inline;
        border: none;
        background: none;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: inherit;
        text-align: left;
        text-decoration: underline;
        cursor: pointer;

        &:disabled {
            text-decoration: none;
            cursor: default;
        }

        &:hover {
            text-decoration: none;
        }
    }

    & > * {
        &:not(:first-child) {
            border-top: $style-border;
        }
    }
}

.fade {
    &-enter,
    &-leave-to {
        max-height: 0;
        opacity: 0;
    }

    &-leave,
    &-enter-to {
        max-height: 400px;
        opacity: 1;
    }

    &-enter-active,
    &-leave-active {
        transition-property: max-height, opacity;
        transition-duration: 0.2s;
    }
}
</style>
